import { Grid, Text, Button, TextInput } from "@mantine/core";

export default function SearchComponent({
  form,
  handleSubmit,
  organization,
  classes,
  CardComponent,
}) {
  return (
    <CardComponent title="Buscar " subtitle="titular" visible={false}>
      <Grid align="center">
        <Grid.Col span="auto">
          <Text size="sm" color="white">
            Ingrese alguno de los campos para realizar la búsqueda de un titular
          </Text>
        </Grid.Col>
      </Grid>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid grow>
          {organization !== "cenit" && (
            <>
              <Grid.Col span={12}>
                <TextInput
                  label="Registro"
                  placeholder="Ingrese el registro del titular"
                  classNames={{
                    rightSection: classes.rightSection,
                    label: classes.label,
                    input: classes.input,
                  }}
                  {...form.getInputProps("register")}
                  disabled={
                    form.values.alias.length > 0 || form.values.doc.length > 0
                  }
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput
                  label="Nombres y/o apellidos"
                  placeholder="Ingrese nombre del titular"
                  classNames={{
                    rightSection: classes.rightSection,
                    label: classes.label,
                    input: classes.input,
                  }}
                  {...form.getInputProps("alias")}
                  disabled={
                    form.values.register.length > 0 ||
                    form.values.doc.length > 0
                  }
                />
              </Grid.Col>
            </>
          )}
          <Grid.Col span={12}>
            <TextInput
              label="Núm. de identidad"
              placeholder="Ingrese el documento de identidad del titular"
              classNames={{
                rightSection: classes.rightSection,
                label: classes.label,
                input: classes.input,
              }}
              {...form.getInputProps("doc")}
              disabled={
                form.values.alias.length > 0 || form.values.register.length > 0
              }
            />
          </Grid.Col>
          <Grid.Col
            xl={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            <Button className={classes.button} type="submit">
              Buscar
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </CardComponent>
  );
}
