import { useEffect, useState } from "react";
import {
  images,
  useStyles,
  CardComponent,
  ModalComponent,
  CardAux,
} from "@mfe/js-common-ave-uiutils";
import { Grid, BackgroundImage } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";

import SearchComponent from "../components/search.component";
import BeneficiaryResultComponent from "../components/beneficiaryResult.component";

import {
  useIsAuthenticated,
  MsalProvider,
  useMsal,
} from "@mfe/react-common-ave-msal";
import {
  getAccessTokenApi,
  getAidHolderData,
  fetchActiveBeneficiary,
  getAidBeneficiariesData,
  getAge,
  parseDate,
} from "@mfe/ts-common-ave-utilitaries";
import { navigateToUrl } from "single-spa";

export default function Outpatientcare({ organization, msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <OutpatientcareComponents organization={organization} />
    </MsalProvider>
  );
}

export const OutpatientcareComponents = ({ organization }) => {
  const { classes } = useStyles();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [tokenApi, setTokenApi] = useState("");
  const [holderData, setHolderData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [beneficiaryData, setBeneficiaryData] = useState([]);
  const [
    beneficiaryWithAccompanyingSelectedIndex,
    setBeneficiaryWithAccompanyingSelectedIndex,
  ] = useState(null);
  const [showBeneficiaries, setShowBeneficiaries] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);

  const [infoToSave, setInfoToSave] = useState({
    userSelected: null,
    withAccompanying: false,
    holder: null,
  });

  const form = useForm({
    initialValues: {
      register: "",
      alias: "",
      doc: "",
    },
  });
  useEffect(() => {
    getAccessTokenApi(instance, accounts, setTokenApi);
  }, []);

  useEffect(() => {
    localStorage.removeItem("isEdit");
  }, []);

  const handleSubmit = (values) => {
    setHolderData(null);
    setShowBeneficiaries(false);
    setBeneficiaryData([]);
    const payload =
      organization !== "cenit"
        ? values
        : {
            register: values.doc,
            alias: "",
            doc: "",
          };
    if (tokenApi)
      getAidHolderData(setHolderData, tokenApi, payload, setLoading);
  };

  const handleGetBeneficiaryData = (user) => {
    setInfoToSave((prev) => ({
      ...prev,
      holder: user,
    }));
    getAidBeneficiariesData(
      setBeneficiaryData,
      tokenApi,
      organization !== "cenit" ? user?.registro : user?.doc,
      setLoading
    );
  };

  const validateChecked = (user) => {
    return getAge(user?.fechaNac) < 18 || getAge(user?.fechaNac) >= 65;
  };

  const handleCheck = (index) => {
    if (beneficiaryWithAccompanyingSelectedIndex === index)
      setBeneficiaryWithAccompanyingSelectedIndex(null);
    else setBeneficiaryWithAccompanyingSelectedIndex(index);
  };

  const handleNavigate = async (beneficiary) => {
    try {
      setLoadingOverlay(true);
      const { data } = await fetchActiveBeneficiary(tokenApi, {
        Correo: [beneficiary.email],
        NumeroDocumento: [beneficiary.doc],
      });
      if (!data?.length || data?.[0]?.PermiteAux) {
        setInfoToSave((prev) => ({
          ...prev,
          userSelected: beneficiary,
          withAccompanying:
            !!beneficiaryData[beneficiaryWithAccompanyingSelectedIndex] ||
            validateChecked(beneficiary),
        }));
        navigateToUrl("/auxilios");
      } else {
        open();
        const updatedBeneficiaryData = beneficiaryData.map((item) => {
          if (item.doc === beneficiary.doc) {
            return { ...item, disabled: true };
          } else {
            return item;
          }
        });
        setBeneficiaryData(updatedBeneficiaryData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOverlay(false);
    }
  };

  useEffect(() => {
    if (infoToSave.holder && infoToSave.userSelected)
      localStorage.setItem("aidBeneficiaryData", JSON.stringify(infoToSave));
  }, [infoToSave]);

  const beneficiaryResultComponentProps = {
    loading,
    holderData,
    classes,
    CardComponent,
    ModalComponent,
    getAge,
    parseDate,
    CardAux,
    loadingOverlay,
    opened,
    close,
    handleGetBeneficiaryData,
    handleNavigate,
    handleCheck,
    validateChecked,
    beneficiaryData,
    showBeneficiaries,
    setShowBeneficiaries,
  };

  const searchComponentProps = {
    form,
    handleSubmit,
    organization,
    classes,
    CardComponent,
  };

  return isAuthenticated ? (
    <BackgroundImage
      src={images.skyImageBackground}
      className={classes.backgroundImage}
    >
      <Grid
        w={{ xs: "100%", md: "80%" }}
        mx="auto"
        sx={(theme) => ({
          [theme.fn.smallerThan("md")]: {
            height: "100%",
          },
        })}
      >
        <Grid.Col md={5}>
          <SearchComponent {...searchComponentProps} />
        </Grid.Col>
        <Grid.Col md={7}>
          <BeneficiaryResultComponent {...beneficiaryResultComponentProps} />
        </Grid.Col>
      </Grid>
    </BackgroundImage>
  ) : null;
};
