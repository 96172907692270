import { Grid, Text, Flex, Checkbox, Button } from "@mantine/core";

const UserCard = ({
  isBeneficiary = false,
  onClick,
  user,
  onChange = () => {},
  defaultChecked = null,
  parseDate,
  getAge,
  classes,
  CardAux,
}) => {
  return (
    <CardAux>
      <Grid align="center">
        <Grid.Col span={12}>
          <Text size="sm" color="#004237" ta="center" fw={700} fz="md">
            {`${user?.nombres} ${user?.apellidos}`}
          </Text>
          <Flex
            gap={8}
            justify="center"
            align="center"
            wrap="wrap"
            sx={(theme) => ({
              [theme.fn.smallerThan("md")]: {
                flexDirection: "column",
              },
              textWrap: "balance",
            })}
          >
            <Text size="sm" color="#666">
              <Text span fw={500}>
                {user?.tipoDoc}:
              </Text>
              {user?.doc}
            </Text>
            <Text size="sm" color="#666">
              <Text span fw={500}>
                Registro:
              </Text>
              {user?.registro}
            </Text>
            {!isBeneficiary && (
              <Text size="sm" color="#666">
                <Text span fw={500}>
                  Cargo:
                </Text>
                {user?.descFuncionario}
              </Text>
            )}
            {isBeneficiary && (
              <Text size="sm" color="#666">
                <Text span fw={500}>
                  Edad:
                </Text>
                {getAge(user?.fechaNac)} años
              </Text>
            )}
            {isBeneficiary && (
              <Text size="sm" color="#666">
                <Text span fw={500}>
                  F. Nacimiento:
                </Text>
                {parseDate(user?.fechaNac)}
              </Text>
            )}
            {isBeneficiary && user?.genero && (
              <Text size="sm" color="#666">
                <Text span fw={500}>
                  Género:
                </Text>
                {user?.genero}
              </Text>
            )}
            {isBeneficiary && (
              <Text size="sm" color="#666">
                <Text span fw={500}>
                  Ciudad:
                </Text>
                {user?.ciudad}
              </Text>
            )}
            {!isBeneficiary && (
              <Text size="sm" color="#666">
                <Text span fw={500}>
                  Regional:
                </Text>
                {user?.regional}
              </Text>
            )}
          </Flex>
        </Grid.Col>
        {isBeneficiary && (
          <Grid.Col xs={12} md={6}>
            {defaultChecked ? (
              <Checkbox
                className={classes.checkBox}
                checked={defaultChecked}
                label="Con acompañante"
                readOnly
              />
            ) : (
              <Checkbox
                className={classes.checkBox}
                onChange={onChange}
                label="Con acompañante"
              />
            )}
          </Grid.Col>
        )}
        <Grid.Col xs={12} md={isBeneficiary ? 6 : 12}>
          <Button
            className={classes.button}
            fullWidth
            data-testid="button-userCard"
            onClick={onClick}
            disabled={user?.disabled}
          >
            Seleccionar
          </Button>
        </Grid.Col>
      </Grid>
    </CardAux>
  );
};

export default UserCard;
