import { Flex, Text, ScrollArea, LoadingOverlay } from "@mantine/core";
import UserCard from "./userCard.component";

export default function BeneficiaryResultComponent({
  loading,
  holderData,
  classes,
  CardComponent,
  ModalComponent,
  getAge,
  parseDate,
  CardAux,
  loadingOverlay,
  opened,
  close,
  handleGetBeneficiaryData,
  handleNavigate,
  handleCheck,
  validateChecked,
  beneficiaryData,
  showBeneficiaries,
  setShowBeneficiaries,
}) {
  if (loadingOverlay) {
    return (
      <LoadingOverlay
        visible={loadingOverlay}
        overlayBlur={50}
        loaderProps={{ color: "#cbd300" }}
      />
    );
  }

  return (
    <>
      <ModalComponent title="Alerta" opened={opened} onClose={close}>
        <Text ta="center" fw={700} color="#004236">
          Ha excedido la cantidad máxima de auxilios con este beneficiario
        </Text>
      </ModalComponent>
      <CardComponent
        title="Resultados "
        subtitle={showBeneficiaries ? "Beneficiario" : "Titular"}
        visible={loading}
        loadingText="Cargando datos de SAP"
      >
        <Flex direction="column">
          {holderData?.length > 0 && (
            <Text size="sm" color="white">
              {`Se han encontrado ${
                showBeneficiaries ? beneficiaryData.length : holderData.length
              }`}
              {holderData.length === 1 || beneficiaryData.length === 1
                ? showBeneficiaries
                  ? " beneficiarios."
                  : " titulares."
                : showBeneficiaries
                ? " beneficiario."
                : " titular."}
            </Text>
          )}
          <ScrollArea
            h={
              holderData?.length > 2 || beneficiaryData?.length > 2
                ? 510
                : "auto"
            }
          >
            {holderData?.length > 0 && !showBeneficiaries
              ? holderData?.map((user, index) => (
                  <UserCard
                    key={index}
                    onClick={() => {
                      setShowBeneficiaries(true);
                      handleGetBeneficiaryData(user);
                    }}
                    user={user}
                    parseDate={parseDate}
                    getAge={getAge}
                    classes={classes}
                    CardAux={CardAux}
                  />
                ))
              : holderData?.length === 0 &&
                !showBeneficiaries && (
                  <Text fw={700} color="#fff">
                    No se encontraron resultados.
                  </Text>
                )}
            {beneficiaryData?.length > 0 &&
              showBeneficiaries &&
              beneficiaryData?.map((beneficiary, index) => (
                <UserCard
                  key={index}
                  onClick={() => handleNavigate(beneficiary)}
                  user={beneficiary}
                  isBeneficiary
                  onChange={() => handleCheck(index)}
                  defaultChecked={validateChecked(beneficiary)}
                  parseDate={parseDate}
                  getAge={getAge}
                  classes={classes}
                  CardAux={CardAux}
                />
              ))}
          </ScrollArea>
        </Flex>
      </CardComponent>
    </>
  );
}
